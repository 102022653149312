<template>
<div class="v-service">
  <div class="service-about">
    <div v-for="item in analysis_list" :key="item">
      <div class="service-header analysis">
        {{ item.name }}
      </div>
      <div v-for="analysis_group in item.analysis_groups" :key="analysis_group">
        <div class="service-header mini">
          {{ analysis_group.name }}
        </div>
        <div class="price-table">
        <div class="price-line"></div>
        <div v-for="analys in analysis_group.analysis" :key="analys">
            <div :class="analysis_group.size">
              <div class="price-name">
                {{ analys.name }}
              </div>
              <div class="price-price">
                {{ analys.price }}
              </div>
            </div>
            <div class="price-line"></div>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
    name: 'v-analysis',
    props: {
    },
    mounted() {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content = "Клиника здоровья анализы";
      document.body.appendChild(meta);
    },
    data() {
        return {
          analysis_list: [
            {
              name: 'Исследования крови',
              analysis_groups: [
                {
                  name: 'Общеклинические исследования крови',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Общий клинический анализ крови, лейкоцитарная формула, СОЭ',
                      price: '300 руб'
                    },
                    {
                      name: 'Общий  клинический анализ крови (без ЛФ)',
                      price: '180 руб'
                    },
                    {
                      name: 'Общий  клинический анализ крови (без ЛФ, с СОЭ)',
                      price: '200 руб'
                    },
                    {
                      name: 'Лейкоцитарная формула (ЛФ) (микроскопия)',
                      price: '150 руб'
                    },
                    {
                      name: 'СОЭ',
                      price: '100 руб'
                    },
                    {
                      name: 'Ретикулоциты',
                      price: '100 руб'
                    },
                    {
                      name: 'LE-клетки',
                      price: '200 руб'
                    },
                    {
                      name: 'Базофильная зернистость эритроцитов',
                      price: '120 руб'
                    },
                  ]
                },
                {
                  name: 'Иммуногематология',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Группа крови',
                      price: '250 руб'
                    },
                    {
                      name: 'Резус-фактор (Rh)',
                      price: '250 руб'
                    },
                    {
                      name: 'АТ к резус-фактору',
                      price: '400 руб'
                    },
                    {
                      name: 'Исследование АТ к антигенам эритроцитов в сыворотке крови  ',
                      price: '650 руб'
                    },
                  ]
                }
              ]
            },
            {
              name: 'Исследования системы гемостаза',
              analysis_groups: [
                {
                  name: 'Коагулограмма. Исследование гемостаза',
                  size: 'price-row lor-analysis',
                  analysis: [
                    {
                      name: 'Коагулограмма: АЧТВ; Протромбиновое время + МНО; Тромбиновое время; Фибриноген; Антитромбин III; (комплекс)',
                      price: '1 650 руб'
                    },
                    {
                      name: 'Протромбиновое время',
                      price: '250 руб'
                    },
                    {
                      name: 'Протромбиновый индекс (ПТИ)',
                      price: '250 руб'
                    },
                    {
                      name: 'МНО',
                      price: '250 руб'
                    },
                    {
                      name: 'АЧТВ',
                      price: '250 руб'
                    },
                    {
                      name: 'Тромбиновое время',
                      price: '250 руб'
                    },
                    {
                      name: 'Фибриноген',
                      price: '250 руб'
                    },
                    {
                      name: 'Антитромбин III',
                      price: '650 руб'
                    },
                    {
                      name: 'Протеин С',
                      price: '1 000 руб'
                    },
                    {
                      name: 'D-Димер',
                      price: '650 руб'
                    },
                    {
                      name: 'Волчаночный антикоагулянт',
                      price: '800 руб'
                    },
                    {
                      name: 'Подтверждающий тест с ядом гадюки Рассела',
                      price: '800 руб'
                    },
                    {
                      name: 'Концентрация плазминогена',
                      price: '600 руб'
                    },
                    {
                      name: 'РФМК',
                      price: '250 руб'
                    },
                    {
                      name: 'Фактор Виллебранда',
                      price: '1 500 руб'
                    },
                    {
                      name: '"ЭЛИ-АФС-ХГЧ-Тест-6" (антифосфолипидный синдром, анти-ХГЧ синдром, состояние имунной системы) понедельник, среда, пятница ЦЛД',
                      price: '1 700 руб'
                    },
                  ]
                },
                {
                  name: 'Агрегатограмма',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Агрегация тромбоцитов, индуцированная адреналином (2,5 мкг/мл)',
                      price: '300 руб'
                    },
                    {
                      name: 'Агрегация тромбоцитов, индуцированная адреналином (5,0 мкг/мл)',
                      price: '300 руб'
                    },
                    {
                      name: 'Агрегация тромбоцитов, индуцированная АДФ (2,5 мкг/мл)',
                      price: '250 руб'
                    },
                    {
                      name: 'Агрегация тромбоцитов, индуцированная АДФ (5,0 мкг/мл)',
                      price: '250 руб'
                    },
                    {
                      name: 'Агрегация тромбоцитов, индуцированная коллагеном (20,0 мкг/мл)',
                      price: '250 руб'
                    },
                    {
                      name: 'Агрегация тромбоцитов, индуцированная ристомицином (15,0 мкг/мл)',
                      price: '380 руб'
                    },
                  ]
                }
              ]
            },
            {
              name: 'Гормоны',
              analysis_groups: [
                {
                  name: 'Тиреоидная панель',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'ТТГ (Тиреотропный гормон) ',
                      price: '300 руб'
                    },
                    {
                      name: 'Т4 (Тироксин) общий',
                      price: '300 руб'
                    },
                    {
                      name: 'Т4 (Тироксин) свободный',
                      price: '300 руб'
                    },
                    {
                      name: 'Т3 (Трийодтиронин) общий',
                      price: '300 руб'
                    },
                    {
                      name: 'Т3 (Трийодтиронин) свободный',
                      price: '300 руб'
                    },
                    {
                      name: 'ТГ (Тиреоглобулин)',
                      price: '450 руб'
                    },
                    {
                      name: 'Тироксин-связывающая способность сыворотки (Т - Uptake)',
                      price: '650 руб'
                    },
                    {
                      name: 'Кальцитонин',
                      price: '800 руб'
                    },
                    {
                      name: 'Прокальцитонин',
                      price: '1 400 руб'
                    },
                  ]
                },
                {
                  name: 'Фертильность и репродукция',
                  size: 'price-row lor-analysis',
                  analysis: [
                    {
                      name: 'ЛГ (Лютеинезирующий гормон)',
                      price: '320 руб'
                    },
                    {
                      name: 'ФСГ (Фолликулостимулирующий гормон)',
                      price: '320 руб'
                    },
                    {
                      name: 'Эстрадиол',
                      price: '320 руб'
                    },
                    {
                      name: 'Тестостерон',
                      price: '320 руб'
                    },
                    {
                      name: 'Свободный тестостерон',
                      price: '700 руб'
                    },
                    {
                      name: 'Пролактин',
                      price: '320 руб'
                    },
                    {
                      name: 'Пролактин с определением макропролактина ',
                      price: '500 руб'
                    },
                    {
                      name: 'ГСПГ (Глобулин, связывающий половые гормоны)',
                      price: '400 руб'
                    },
                    {
                      name: 'Прогестерон',
                      price: '320 руб'
                    },
                    {
                      name: 'Дигидротестостерон',
                      price: '1000 руб'
                    },
                    {
                      name: '17-ОН-прогестерон',
                      price: '350 руб'
                    },
                    {
                      name: 'АМГ (Антимюллеров гормон) ',
                      price: '1 500 руб'
                    },
                    {
                      name: 'Ингибин В  ',
                      price: '1 500 руб'
                    },
                    {
                      name: 'Ингибин А',
                      price: '1 950 руб'
                    },
                    {
                      name: 'Эстриол свободный',
                      price: '350 руб'
                    },
                    {
                      name: 'бета- ХГЧ общий (бета-субъединица хорионического гонадотропина человека)',
                      price: '380 руб'
                    },
                    {
                      name: 'бета - ХГЧ свободный (бета-субъединица хорионического гонадотропина человека)',
                      price: '500 руб'
                    },
                    {
                      name: 'бета- ХГЧ общий (моча)',
                      price: '300 руб'
                    },
                    {
                      name: 'Плацентарный лактоген  ',
                      price: '500 руб'
                    },
                    {
                      name: 'LHCGR(rs2293275 C/T) Рецептор лютеинизирубщего гормона/хориогонадотропина (понедельник, среда, пятница в ЦЛД)',
                      price: '350 руб'
                    },
                  ]
                },
                {
                  name: 'Гормоны надпочечников',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Альдостерон',
                      price: '600 руб'
                    },
                    {
                      name: 'Кортизол',
                      price: '380 руб'
                    },
                    {
                      name: 'Кортизол в слюне',
                      price: '800 руб'
                    },
                    {
                      name: 'Свободный кортизол в суточной моче (указать рост, вес, V суточный)',
                      price: '900 руб'
                    },
                    {
                      name: 'ДГЭАС (Дегидроэпиандростерон сульфат) ',
                      price: '380 руб'
                    },
                    {
                      name: 'Андростендион',
                      price: '900 руб'
                    },
                  ]
                },
                {
                  name: 'Гормоны гипофиза',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Соматотропный гормон (СТГ)',
                      price: '450 руб'
                    },
                    {
                      name: 'АКТГ (Адренокортикотропный гормон ) ',
                      price: '800 руб'
                    },
                  ]
                },
                {
                  name: 'Гормоны поджелудочной железы',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Инсулин',
                      price: '400 руб'
                    },
                    {
                      name: 'С-пептид',
                      price: '400 руб'
                    },
                    {
                      name: 'Проинсулин',
                      price: '800 руб'
                    },
                    {
                      name: 'Индекс HOMA-IR (инсулин, глюкоза, рассчет инсулинорезистентности)',
                      price: '500 руб'
                    },
                  ]
                },
                {
                  name: 'Другие гормоны',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Гастрин',
                      price: '700 руб'
                    },
                    {
                      name: 'Инсулиноподобный фактор роста I (Соматомедин С)',
                      price: '600 руб'
                    },
                    {
                      name: 'Паратгормон',
                      price: '600 руб'
                    },
                    {
                      name: 'Лептин',
                      price: '750 руб'
                    },
                    {
                      name: 'Ренин',
                      price: '900 руб'
                    },
                    {
                      name: 'Эритропоэтин',
                      price: '500 руб'
                    },
                    {
                      name: 'Мелатонин',
                      price: '2 700 руб'
                    },
                  ]
                },
                {
                  name: 'Лекарственный мониторинг',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Вальпроевая кислота (кровь)  ',
                      price: '1 200 руб'
                    },
                  ]
                },
              ]
            },
            {
              name: 'Аутоиммунные заболевания',
              analysis_groups: [
                {
                  name: '',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'АТ к тиреоглобулину (АТ к ТГ)',
                      price: '400 руб'
                    },
                    {
                      name: 'АТ к тиреоидной пероксидазе (АТ к ТПО)',
                      price: '500 руб'
                    },
                    {
                      name: 'АТ к рецепторам ТТГ (тиреотропного гормона) ',
                      price: '1 200 руб'
                    },
                    {
                      name: 'АТ к ядерным антигенам (ANA) (кач.)',
                      price: '550 руб'
                    },
                    {
                      name: 'АТ к фосфолипидам lgG; lgM',
                      price: '900 руб'
                    },
                    {
                      name: 'АТ к митохондриям (АМА)  ',
                      price: '900 руб'
                    },
                    {
                      name: 'АТ к инсулину',
                      price: '750 руб'
                    },
                    {
                      name: 'АТ к β-клеткам поджелудочной железы  ',
                      price: '1 300 руб'
                    },
                    {
                      name: 'АТ к гладкой мускулатуре  ',
                      price: '1 300 руб'
                    },
                    {
                      name: 'АТ к париентальным клеткам желудка  ',
                      price: '1 400 руб'
                    },
                    {
                      name: 'АТ к микросомам печени и почек (Аnti - LKM1)  ',
                      price: '1 300 руб'
                    },
                    {
                      name: 'АТ к миокарду  ',
                      price: '1 400 руб'
                    },
                    {
                      name: 'АТ к кардиолипину (IgA + IgM + IgG)',
                      price: '1 000 руб'
                    },
                    {
                      name: 'АТ к кардиолипину (IgA) количественно  ',
                      price: '1 000 руб'
                    },
                    {
                      name: 'АТ к кардиолипину (IgG) количественно  ',
                      price: '1 000 руб'
                    },
                    {
                      name: 'АТ к β-2 Гликопротеину I (IgG + IgA + IgM)  ',
                      price: '1 000 руб'
                    },
                    {
                      name: 'АТ к тканевой трансглутаминазе IgG   ',
                      price: '1 300 руб'
                    },
                    {
                      name: 'АТ к тканевой трансглутаминазе IgА   ',
                      price: '1 300 руб'
                    },
                    {
                      name: 'АТ к нативной ДНК',
                      price: '650 руб'
                    },
                    {
                      name: 'АТ к микросомальной фракции тиреоцитов (АТ-МАГ)',
                      price: '600 руб'
                    },
                    {
                      name: 'Антитела к деамидированным пептидам глиадина IgA (диагностика целиакии)',
                      price: '750 руб'
                    },
                    {
                      name: 'Антитела к деамидированным пептидам глиадина Ig G (диагностика целиакии)',
                      price: '650 руб'
                    },
                    {
                      name: 'Антитела к виментину(АМЦВ) (диагностика ревматоидного артрита)  ',
                      price: '1 200 руб'
                    },
                    {
                      name: 'Антитела к тромбоцитам IgG  ',
                      price: '1 500 руб'
                    },
                    {
                      name: 'Комплекс маркеров аутоиммунного гепатита (IgG к AMA-M2 LKM-1 LC-1 SLA/LP)',
                      price: '1 250 руб'
                    },
                    {
                      name: 'Антиовариальные антитела',
                      price: '1 250 руб'
                    },
                    {
                      name: 'Антиспермальные антитела',
                      price: '800 руб'
                    },
                  ]
                }
              ]
            },
            {
              name: 'Онкомаркеры (Антигены)',
              analysis_groups: [
                {
                  name: '',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'АФП (Альфафетопротеин)',
                      price: '400 руб'
                    },
                    {
                      name: 'РЭА (Раковоэмбриональный антиген)',
                      price: '350 руб'
                    },
                    {
                      name: 'СА-19.9',
                      price: '400 руб'
                    },
                    {
                      name: 'СА-15.3',
                      price: '400 руб'
                    },
                    {
                      name: 'СА-242',
                      price: '1 200 руб'
                    },
                    {
                      name: 'Са-72.4',
                      price: '750 руб'
                    },
                    {
                      name: 'Cyfra-21-1',
                      price: '1 000 руб'
                    },
                    {
                      name: 'ФНО (Фактор некроза опухоли)',
                      price: '1 400 руб'
                    },
                    {
                      name: 'NSE (Нейрон-специфическая энолаза)',
                      price: '650 руб'
                    },
                    {
                      name: 'Бета-2-Микроглобулин',
                      price: '800 руб'
                    },
                    {
                      name: 'ПСА (простатспецифический антиген) общий',
                      price: '400 руб'
                    },
                    {
                      name: 'ПСА (простатспецифический антиген) свободный',
                      price: '400 руб'
                    },
                    {
                      name: 'ПСА общий + ПСА свободный',
                      price: '700 руб'
                    },
                    {
                      name: 'Белок S100',
                      price: '2 500 руб'
                    },
                    {
                      name: 'СА-125',
                      price: '450 руб'
                    },
                    {
                      name: 'HE4 (человеческий эпидидимальный секреторный белок 4)',
                      price: '1 300 руб'
                    },
                    {
                      name: 'Комплекс HE-4, СА-125, индекс риска рака яичников (ROMA 1, ROMA 2)',
                      price: '1 700 руб'
                    },
                  ]
                }
              ]
            },
            {
              name: 'Биохимические исследования крови',
              analysis_groups: [
                {
                  name: 'Специфические белки',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'АСЛ-О (Антистрептолизин-О)',
                      price: '400 руб'
                    },
                    {
                      name: 'Гаптоглобин',
                      price: '550 руб'
                    },
                    {
                      name: 'С-реактивный белок (ультрачувствительный)',
                      price: '350 руб'
                    },
                    {
                      name: 'Ревматоидный фактор',
                      price: '250 руб'
                    },
                    {
                      name: 'Преальбумин',
                      price: '1 500 руб'
                    },
                    {
                      name: 'Церулоплазмин',
                      price: '600 руб'
                    },
                    {
                      name: 'Фруктозамин',
                      price: '400 руб'
                    },
                    {
                      name: 'Катионный протеин эозинофилов',
                      price: '700 руб'
                    },
                  ]
                },
                {
                  name: 'Витамины',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'В-12 (Цианокобаламин)',
                      price: '500 руб'
                    },
                    {
                      name: 'Витамин B1 (Тиамин)',
                      price: '2 000 руб'
                    },
                    {
                      name: 'Витамин B6 (Пиридоксин)',
                      price: '2 000 руб'
                    },
                    {
                      name: '25-ОН Витамин D',
                      price: '1 500 руб'
                    },
                    {
                      name: 'Фолиевая кислота',
                      price: '450 руб'
                    },
                  ]
                },
                {
                  name: 'Ферменты',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'АЛТ (аланинаминотрансфераза',
                      price: '150 руб'
                    },
                    {
                      name: 'АСТ (аспартатаминотрансфераза)',
                      price: '150 руб'
                    },
                    {
                      name: 'Альфа-амилаза',
                      price: '170 руб'
                    },
                    {
                      name: 'Амилаза панкреатическая',
                      price: '300 руб'
                    },
                    {
                      name: 'ГГТ (гаммаглутаматтранспептидаза)',
                      price: '150 руб'
                    },
                    {
                      name: 'Креатинкиназа (КК, Креатинфосфокиназа ) ',
                      price: '150 руб'
                    },
                    {
                      name: 'Креатинкиназа - МВ (КК-МВ, Креатинфосфокиназа - миокардиальная фракция)',
                      price: '170 руб'
                    },
                    {
                      name: 'Лактатдегидрогеназа (ЛДГ)',
                      price: '200 руб'
                    },
                    {
                      name: 'Липаза',
                      price: '150 руб'
                    },
                    {
                      name: 'Щелочная фосфатаза (ЩФ)',
                      price: '150 руб'
                    },
                    {
                      name: 'Холинэстераза сывороточная (ХЭ)',
                      price: '200 руб'
                    },
                    {
                      name: 'Пепсиноген I (Pepsinogen I) ',
                      price: '500 руб'
                    },
                    {
                      name: 'Пепсиноген II (Pepsinogen II) ',
                      price: '500 руб'
                    },
                  ]
                },
                {
                  name: 'Обмен пигментов',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Билирубин общий',
                      price: '150 руб'
                    },
                    {
                      name: 'Билирубин прямой',
                      price: '150 руб'
                    },
                  ]
                },
                {
                  name: 'Субстраты',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Креатинин',
                      price: '150 руб'
                    },
                    {
                      name: 'Мочевина',
                      price: '150 руб'
                    },
                    {
                      name: 'Мочевая кислота',
                      price: '150 руб'
                    },
                  ]
                },
                {
                  name: 'Функциональные тесты',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Проба Реберга (кровь + суточная моча, указать рост, вес, возраст)',
                      price: '300 руб'
                    },
                    {
                      name: 'СКФ (Скорость клубочковой фильтрации) (кровь, указать рост, вес, возраст)',
                      price: '180 руб'
                    },
                  ]
                },
                {
                  name: 'Обмен железа и диагностика анемий',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Железо',
                      price: '150 руб'
                    },
                    {
                      name: 'Латентная железосвязывающая способность (ЛЖСС) ',
                      price: '150 руб'
                    },
                    {
                      name: 'Трансферрин',
                      price: '450 руб'
                    },
                    {
                      name: 'Углевод-дефицитный трансферрин, CDT ',
                      price: '2 600 руб'
                    },
                    {
                      name: 'Ферритин',
                      price: '450 руб'
                    },
                    {
                      name: 'Общая железосвязывающая способность (ОЖСС) + Индекс насыщения трансферрина',
                      price: '170 руб'
                    },
                  ]
                },
                {
                  name: 'Аминокислоты: пептиды',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Катехоламины (адреналин; норадреналин; дофамин)',
                      price: '2 100 руб'
                    },
                    {
                      name: 'Нефрины в плазме (метанефрин и норметанефрин)',
                      price: '1 800 руб'
                    },
                    {
                      name: 'Гомоцистеин',
                      price: '900 руб'
                    },
                    {
                      name: 'Серотонин',
                      price: '2 900 руб'
                    },
                  ]
                },
                {
                  name: 'Электролиты',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Кальций',
                      price: '150 руб'
                    },
                    {
                      name: 'Кальций ионизированный',
                      price: '450 руб'
                    },
                    {
                      name: 'Калий',
                      price: '150 руб'
                    },
                    {
                      name: 'Натрий',
                      price: '150 руб'
                    },
                    {
                      name: 'Хлор',
                      price: '150 руб'
                    },
                    {
                      name: 'Магний',
                      price: '150 руб'
                    },
                    {
                      name: 'Фосфор неорганический',
                      price: '170 руб'
                    },
                    {
                      name: 'Калий, натрий, кальций (комплекс)',
                      price: '300 руб'
                    },
                    {
                      name: 'Калий; натрий; хлор (комплекс)',
                      price: '300 руб'
                    },
                  ]
                },
                {
                  name: 'Липиды',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Триглицериды',
                      price: '150 руб'
                    },
                    {
                      name: 'Холестерин общий (ХС)',
                      price: '150 руб'
                    },
                    {
                      name: 'XC ЛПВП (холестерин липопротеинов высокой плотности)',
                      price: '170 руб'
                    },
                    {
                      name: 'XC ЛПНП (холестерин липопротеинов низкой плотности)',
                      price: '170 руб'
                    },
                    {
                      name: 'Аполипопротеин А1',
                      price: '450 руб'
                    },
                    {
                      name: 'Аполипопротеин В',
                      price: '450 руб'
                    },
                    {
                      name: 'Липопротеин (а)',
                      price: '600 руб'
                    },
                  ]
                },
                {
                  name: 'Обмен белков',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Альбумин',
                      price: '150 руб'
                    },
                    {
                      name: 'Общий белок',
                      price: '150 руб'
                    },
                    {
                      name: 'Протеинограмма: общий белок; белковые фракции',
                      price: '170 руб'
                    },
                  ]
                },
                {
                  name: 'Осадочные пробы',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Тимоловая проба',
                      price: '150 руб'
                    },
                  ]
                },
                {
                  name: 'Маркеры остеопороза',
                  size: 'price-row lor-analysis',
                  analysis: [
                    {
                      name: 'B-Cross laps (С-концевые телопептиды коллагена I типа - диагностический маркер процесса остеопороза)',
                      price: '1000 руб'
                    },
                    {
                      name: 'P1NP (N-терминальный пропептид проколлагена 1 типа - диагностический маркер процесса регенерации костной ткани)',
                      price: '1 350 руб'
                    },
                    {
                      name: 'Остеокальцин',
                      price: '550 руб'
                    },
                  ]
                },
                {
                  name: 'Кардиомаркеры',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Миоглобин',
                      price: '650 руб'
                    },
                    {
                      name: 'Тропонин I',
                      price: '450 руб'
                    },
                  ]
                },
                {
                  name: 'Микроэлементы и токсичные соединения в крови',
                  size: 'price-row lor-analysis',
                  analysis: [
                    {
                      name: 'Медь',
                      price: '1 050 руб'
                    },
                    {
                      name: 'Основные эссенциальные микроэлементы в сыворотке крови (медь, селен, цинк)',
                      price: '1 500 руб'
                    },
                    {
                      name: 'Токсичные элементы в цельной крови (тяжелые металлы: кадмий, ртуть, свинец)',
                      price: '1 500 руб'
                    },
                    {
                      name: 'Микроэлементы в цельной крови и сыворотке скрининг (магний, алюминий, кальций, марганец, железо, кобальт, медь, цинк, мышьяк, селен, кадмий, ртуть, свинец)',
                      price: '3 500 руб'
                    },
                    {
                      name: 'Карбоксигемоглобин',
                      price: '500 руб'
                    },
                    {
                      name: 'Метгемоглобин ',
                      price: '500 руб'
                    },
                  ]
                },
              ]
            },
            {
              name: 'Серологическая диагностика инфекций',
              analysis_groups: [
                {
                  name: 'ВИЧ',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'ВИЧ 1-2 АГ / АТ (скрининг) (ИФА) ',
                      price: '300 руб'
                    },
                  ]
                },
                {
                  name: 'Сифилис',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'АТ к Treponema pallidum (скрининг) (ИФА) ',
                      price: '300 руб'
                    },
                    {
                      name: 'АТ к Treponema pallidum IgM (ИФА)  ',
                      price: '280 руб'
                    },
                    {
                      name: 'АТ к Treponema pallidum IgG (ИФА)  ',
                      price: '280 руб'
                    },
                    {
                      name: 'Syphilis RPR (антикардиолипиновый тест) ',
                      price: '160 руб'
                    },
                    {
                      name: 'Syphilis TPHA (АТ к Treponema pallidum, РПГА)  ',
                      price: '280 руб'
                    },
                  ]
                },
                {
                  name: 'Гепатит В',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'HbsAg (скрининг) (ИФА)',
                      price: '300 руб'
                    },
                    {
                      name: 'HbeAg',
                      price: '250 руб'
                    },
                    {
                      name: 'анти-HBc-IgG',
                      price: '200 руб'
                    },
                    {
                      name: 'анти-HBe-IgG',
                      price: '200 руб'
                    },
                    {
                      name: 'HBcAg-IgM',
                      price: '250 руб'
                    },
                    {
                      name: 'Anti-HBs',
                      price: '250 руб'
                    },
                  ]
                },
                {
                  name: 'Гепатит С',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Anti-HCV (скрининг) (ИФА)',
                      price: '300 руб'
                    },
                    {
                      name: 'Anti-HCV (core, NS3, NS4, NS5)',
                      price: '350 руб'
                    },
                    {
                      name: 'Anti-HCV IgM',
                      price: '250 руб'
                    },
                  ]
                },
                {
                  name: 'Гепатит А',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Anti-HAV IgM',
                      price: '300 руб'
                    },
                    {
                      name: 'Anti-HAV IgG  ',
                      price: '300 руб'
                    },
                  ]
                },
                {
                  name: 'Гепатит Е',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Anti-HEV IgG  ',
                      price: '700 руб'
                    },
                    {
                      name: 'Anti-HEV IgM  ',
                      price: '700 руб'
                    },
                  ]
                },
                {
                  name: 'Гепатит D',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Anti-HDV сумм. (IgG+IgM)',
                      price: '300 руб'
                    },
                  ]
                },
                {
                  name: 'Герпесвирусы',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Herpes simplex virus I, II IgG',
                      price: '250 руб'
                    },
                    {
                      name: 'Herpes simplex virus I, II IgM ',
                      price: '250 руб'
                    },
                    {
                      name: 'Herpes simplex virus I, II IgG (авидность)',
                      price: '320 руб'
                    },
                    {
                      name: 'Cytomegalovirus IgG ',
                      price: '250 руб'
                    },
                    {
                      name: 'Cytomegalovirus IgM',
                      price: '250 руб'
                    },
                    {
                      name: 'Cytomegalovirus IgG (авидность)',
                      price: '320 руб'
                    },
                    {
                      name: 'Epstein Barr virus (АТ IgM к капсидному АГ)',
                      price: '500 руб'
                    },
                    {
                      name: 'Epstein Barr virus (АТ IgG к раннему АГ)',
                      price: '250 руб'
                    },
                    {
                      name: 'Epstein Barr virus (АТ IgG к позднему АГ)',
                      price: '250 руб'
                    },
                    {
                      name: 'Varicella zoster virus lgG  ',
                      price: '800 руб'
                    },
                    {
                      name: 'Varicella zoster virus IgМ  ',
                      price: '800 руб'
                    },
                  ]
                },
                {
                  name: 'Другие вирусные инфекции',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Rubella virus IgG',
                      price: '250 руб'
                    },
                    {
                      name: 'Rubella virus IgM',
                      price: '250 руб'
                    },
                    {
                      name: 'Rubella virus IgG (авидность)',
                      price: '320 руб'
                    },
                    {
                      name: 'Антитела к Кори IgG (количественно)',
                      price: '500 руб'
                    },
                    {
                      name: 'Антитела к Кори IgM (количественно)',
                      price: '1 200 руб'
                    },
                  ]
                },
                {
                  name: 'Трансмиссивные инфекции',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Боррелиоз; болезнь Лайма (Borrelia burgdorferi) lgG',
                      price: '350 руб'
                    },
                    {
                      name: 'Боррелиоз; болезнь Лайма (Borrelia burgdorferi ) lgM',
                      price: '350 руб'
                    },
                    {
                      name: 'Клещевой энцефалит (Tick-borne encephalitis Virus) IgG',
                      price: '350 руб'
                    },
                    {
                      name: 'Клещевой энцефалит (Tick-borne encephalitis Virus) IgМ',
                      price: '350 руб'
                    },
                  ]
                },
                {
                  name: 'Туберкулез',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'АТ к Mycobacterium tuberculosis (ИФА)  ',
                      price: '450 руб'
                    },
                    {
                      name: 'Диагностика туберкулеза методом T-SPOT.TB',
                      price: '7 500 руб'
                    },
                  ]
                },
                {
                  name: 'Моликуты',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Mycoplasma hominis IgG',
                      price: '250 руб'
                    },
                    {
                      name: 'Mycoplasma hominis IgА',
                      price: '250 руб'
                    },
                    {
                      name: 'Mycoplasma pneumoniae IgМ  ',
                      price: '350 руб'
                    },
                    {
                      name: 'Mycoplasma pneumoniae IgG  ',
                      price: '350 руб'
                    },
                    {
                      name: 'Ureaplasma Urealyticum lgA',
                      price: '250 руб'
                    },
                    {
                      name: 'Ureaplasma Urealyticum lgG',
                      price: '250 руб'
                    },
                  ]
                },
                {
                  name: 'Хламидии',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Chlamydia trachomatis lgA',
                      price: '250 руб'
                    },
                    {
                      name: 'Chlamydia trachomatis lgM',
                      price: '250 руб'
                    },
                    {
                      name: 'Chlamydia trachomatis lgG',
                      price: '250 руб'
                    },
                    {
                      name: 'Chlamydia pneumoniae lgM  ',
                      price: '370 руб'
                    },
                    {
                      name: 'Chlamydia pneumoniae lgG  ',
                      price: '370 руб'
                    },
                  ]
                },
                {
                  name: 'Кишечные инфекции',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Helicobacter pylori lgG',
                      price: '250 руб'
                    },
                    {
                      name: 'Yersinia enterocolitica,  pseudotuberculosis   IgG',
                      price: '350 руб'
                    },
                    {
                      name: 'Yersinia enterocolitica,  pseudotuberculosis   IgM',
                      price: '400 руб'
                    },
                    {
                      name: 'АТ к Yersinia pseudotuberculosis (псевдотуберкулез) (РНГА)  ',
                      price: '450 руб'
                    },
                    {
                      name: 'АТ к Yersinia enterocolitica (иерсиниоз) (РПГА)  ',
                      price: '400 руб'
                    },
                  ]
                },
                {
                  name: 'Протозойные инфекции',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Lamblia intestinalis IgM/IgG (сумм)',
                      price: '250 руб'
                    },
                    {
                      name: 'АТ Trichomonas vaginalis lgG',
                      price: '250 руб'
                    },
                    {
                      name: 'Toxoplasma gondii IgG',
                      price: '250 руб'
                    },
                    {
                      name: 'Toxoplasma gondii IgM',
                      price: '250 руб'
                    },
                    {
                      name: 'Toxoplasma gondii IgG (авидность)',
                      price: '320 руб'
                    },
                  ]
                },
                {
                  name: 'Грибковые инфекции',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'АТ к Candida lgG',
                      price: '250 руб'
                    },
                    {
                      name: 'Aspergillus fumigatus IgG (кач.)',
                      price: '300 руб'
                    },
                  ]
                },
                {
                  name: 'Гельминтозы',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'АТ к антигенам токсокар IgG ',
                      price: '250 руб'
                    },
                    {
                      name: 'АТ к антигенам описторхисов IgG',
                      price: '250 руб'
                    },
                    {
                      name: 'АТ к антигенам трихинелл IgG',
                      price: '250 руб'
                    },
                    {
                      name: 'АТ к антигенам эхинококка IgG',
                      price: '250 руб'
                    },
                    {
                      name: 'АТ к антигенам анизакиды IgG',
                      price: '250 руб'
                    },
                    {
                      name: 'АТ к возбудителю аскаридоза (Ascaris lumbricoides) lgG',
                      price: '250 руб'
                    },
                  ]
                },
                {
                  name: 'Другие инфекции',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Ранние антитела (IgМ) к Bortedella pertusis (коклюш)',
                      price: '800 руб'
                    },
                    {
                      name: 'Секреторные антитела (IgA) к Bortedella pertusis (коклюш)',
                      price: '800 руб'
                    },
                    {
                      name: 'Поздние антитела, IgG к Bortedella pertusis (коклюш)',
                      price: '800 руб'
                    },
                    {
                      name: 'РПГА с сыпнотифозным диагностикумом',
                      price: '800 руб'
                    },
                  ]
                },
              ]
            },
            {
              name: 'Типирование антигенов основного комплекса системы гистосовместимости (HLA)',
              analysis_groups: [
                {
                  name: '',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'РЗ4 - НLA II класса DRB1 (секвенирование)(антигены тканевой совместимости)',
                      price: '1 400 руб'
                    },
                    {
                      name: 'РЗ5 - НLA II класса DRB1 (секвенирование) для супружеской пары + интерпретация',
                      price: '3 000 руб'
                    },
                    {
                      name: 'РЗ9 - НLA II класса (DRB1 dQA1 dQB1) (антигены тканевой совместимости)  ',
                      price: '4 000 руб'
                    },
                    {
                      name: 'Р310 - НLA II класса (DRB1 dQA1 dQB1) для супружеской пары  ',
                      price: '6 500 руб'
                    },
                  ]
                }
              ]
            },
            {
              name: 'Цитогенетические исследования',
              analysis_groups: [
                {
                  name: '',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'Анализ кариотипа 1 пациента ',
                      price: '3 000 руб'
                    },
                    {
                      name: 'Анализ кариотипа (с фотографией хромосом) 1 пациента ',
                      price: '3 700 руб'
                    },
                    {
                      name: 'Кариотипирование с выявлением аберраций ',
                      price: '4 200 руб'
                    },
                    {
                      name: 'Кариотипирование (с выявлением аберраций и фотографией хромосом) ',
                      price: '5 200 руб'
                    },
                  ]
                }
              ]
            },
            {
              name: 'Иммунологические исследования',
              analysis_groups: [
                {
                  name: 'Иммуноглобулины',
                  size: 'price-row',
                  analysis: [
                    {
                      name: 'IgA (иммуноглобулин А)',
                      price: '450 руб'          
                    },
                    {
                      name: 'IgM (иммуноглобулин M)',
                      price: '450 руб'          
                    },
                    {
                      name: 'IgG (иммуноглобулин G)',
                      price: '450 руб'          
                    },
                    {
                      name: 'IgE (иммуноглобулин E)',
                      price: '400 руб'          
                    },
                  ]
                },
                {
                  name: 'Имунный статус',
                  size: 'price-row lor-analysis',
                  analysis: [
                    {
                      name: 'Комплексная оценка иммунного статуса - скрининг - основные субпопуляции лимфоцитов (B и Т-лимфоциты), индекс регуляции, NK-клетки общие, NKT-клетки, фагоцитоз общий, иммуноглобулины IgA, IgM, IgG.',
                      price: '3 000 руб',
                    },
                    {
                      name: 'Циркулирующие иммунные комплексы (ЦИК)',
                      price: '250 руб',
                    },
                    {
                      name: 'Циркулирующие иммунные комплексы (ЦИК), содержащие АГ описторхиса',
                      price: '250 руб',
                    },
                  ]       
                }
              ]
            }
          ]
        }
    }
}
</script>

<style>
.analysis {
  text-align: center; 
  margin-top: 40px; 
  margin-bottom: 20px;
}

.mini {
  font-size: 28px;
  opacity: 0.7;
  margin-left: 70px;
}
@media screen and (max-width: 700px) {
    .mini {
      font-size: 20px;
      margin-left: 20px;
    }
    .analysis {
      font-size: 25px;
      margin-left: 0px;
    }
}
</style>
