<template>
  <div class="v-service">
    <div class="service-about">
        <div class="service-header">Офтальмолог</div>
        <div class="service-text">Врач-офтальмолог – специалист с высшим медицинским образованием, который специализируется на диагностике, лечении и профилактике глазных заболеваний.</div>
        <div class="service-text"><b>С чем следует незамедлительно обратиться к врачу-офтальмологу?</b></div>
        <div class="service-text" style="text-align: left;">
            •	Снижение остроты зрения;<br>
            •	«Туман в глазах», зрительные объекты теряют четкость;<br>
            •	Двоение в глазах, появление плавающих помутнений;<br>
            •	Радужные круги вокруг источников света;<br>
            •	Боль, резь, ощущение «песка» в глазах;<br>
            •	Покраснение и сухость глаз;<br>
            •	Слезоточивость глаз или иные выделения из глаз;<br>
            •	Светобоязнь;<br>
            •	Головные боли связанные с ношением очков;<br>
            •	Любые травмы глаз.<br>
        </div>
        <div class="service-text">Следует помнить, что многие заболевания органов глаз практически не проявляют себя на ранних этапах. Поэтому всем, кто заботится о своем зрении, необходимо посещать врача-офтальмолога регулярно - не менее одного раза в год. </div>
        <div class="service-text"><b>Проявить особое внимание к своему зрению стоит:</b></div>
        <div class="service-text" style="text-align: left;">
            •	Тем, кому более 45 лет;<br>
            •	Детям школьного возраста;<br>
            •	Тем кто подвергается значительным зрительным нагрузкам;<br>
            •	Тем, у кого были какие-либо операции на глазах;<br>
            •	Женщинам, планирующим беременность и страдающим близорукостью;<br>
            •	Беременным женщинам;<br>
            •	Людям с диагнозом «сахарный диабет»;<br>
            •	Людям, имеющим наследственную предрасположенность к заболеваниям органов глаз.<br>
        </div>
        <div class="service-header">Услуги врача офтальмолога</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in oftalmolog_services" :key="service">
                <div class="price-row">
                <div class="price-name">{{service.name}}</div>
                <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'v-gastr',
    props: {
    },
    mounted() {
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = "Клиника здоровья офтальмолог";
        document.body.appendChild(meta);
    },
    data() {
        return {
            oftalmolog_services: [
                {
                    name: 'Первичный прием офтальмолога (взрослые)',
                    price: '1 900 руб'
                },
                {
                    name: 'Повторный прием',
                    price: '1 700 руб'
                },
                {
                    name: 'Оформление МСЭ',
                    price: '2 200 руб'
                },
                {
                    name: 'Исследование цветоощущения',
                    price: '200 руб'
                },
                {
                    name: 'Офтальмотонометрия (бесконтактная)',
                    price: '200 руб'
                },
                {
                    name: 'Офтальмотонометрия (по Маклакову)',
                    price: '500 руб'
                },
                {
                    name: 'Скиаскопия',
                    price: '300 руб'
                },
                {
                    name: 'Удаление инородного тела конъюктивы',
                    price: '1 000 руб'
                },
                {
                    name: 'Удаление инородного тела роговицы',
                    price: '1 000 руб'
                },
                {
                    name: 'Забор материала для бак.посева или ПЦР',
                    price: '250 руб'
                },
                {
                    name: 'Снятие швов с коньюктивы, кожи век',
                    price: '300 руб'
                },
                {
                    name: 'Массаж век медицинский(один глаз)',
                    price: '200 руб'
                },
                {
                    name: 'Иньекция под коньюктиву (один глаз) ',
                    price: '300 руб'
                },
                {
                    name: 'Подкожное введение лекарственных препаратов (височная область)',
                    price: '150 руб'
                },
                {
                    name: 'Иньекция парабульбарная',
                    price: '300 руб'
                },
                {
                    name: 'Подбор очковой коррекции зрения (сферические очки)',
                    price: '500 руб'
                },
                {
                    name: 'Подбор очковой коррекции зрения (астигматические очки)',
                    price: '800 руб'
                },
                {
                    name: 'Эпиляция ресниц при завороте, при заборе на демодекс',
                    price: '500 руб'
                },
                {
                    name: 'Периметрия',
                    price: '300 руб'
                },
            ],
        }
    }
}
</script>

<style>
</style>
