<template>
  <div class="v-service-page">
    <div v-for="service in services" :key="service" style="display: inline-block;">
      <router-link :to="{ name: service.route, hash: '#head'}">
        <Card
          :img="service.src"
          :desc="service.desc"
          :name="service.name"
          :buttonVisible="true"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import Card from '@/components/v-service-card.vue'
export default {
  name: 'v-service-page',
  props: {
  },
  components: {
    Card
  },
  mounted() {
    const meta = document.createElement("meta");
    meta.name = "description";
    meta.content = "Клиника здоровья услуги цены";
    document.body.appendChild(meta);
  },
  data() {
    return {
      services: [
        {
          name: 'Гастроэнтеролог', 
          desc: 'Врач-гастроэнтеролог поможет вовремя распознать начало течения болезни, даст рекомендации для быстрого снятия неприятных симптомов и подберет индивидуальную схему лечения.', 
          src: 'Гастроэнтеролог.jpeg',
          route: 'Gastr',
          buttonVisible: true
        },
        {
          name: 'Гинеколог', 
          desc: 'Врач-акушер-гинеколог – специалист с высшим медицинским образованием, который занимается наблюдением состояния репродуктивной системы женщины, лечением возможных заболеваний и профилактикой осложнений.', 
          src: 'Гинеколог.jpg',
          route: 'Ginecolog'
        },
        {
          name: 'Кардиолог', 
          desc: 'Кардиолог - врач, специализирующийся на диагностике, лечении и профилактике сердечно-сосудистых заболеваний.', 
          src: 'Кардиолог.jpeg',
          route: 'Cardiolog'
        },
        {
          name: 'ЛОР', 
          desc: 'ЛОР-врач (оториноларинголог) – это узкопрофильный специалист, занимающийся профилактикой, диагностикой и лечением патологии органов слуха, обоняния, глотки, гортани и трахеи («ухо/горло/нос»), а также прилегающих к ним областей.', 
          src: 'ЛОР.jpg',
          route: 'LOR'
        },
        {
          name: 'Невролог', 
          desc: 'Врач-невролог (невропатолог) – специалист с высшим медицинским образованием, в компетенцию которого входит диагностика, лечение и профилактика болезней, связанных с нервной системой. Это центральная и периферическая нервная системы.', 
          src: 'Невролог.jpg',
          route: 'Nevrolog'
        },
        {
          name: 'Онколог', 
          desc: 'Врач-онколог занимается диагностикой, лечением опухолей, а также изучением преобразования доброкачественных опухолей в злокачественные.', 
          src: 'онколог.jpeg',
          route: 'Onkolog'
        },
        {
          name: 'Офтальмолог', 
          desc: 'Врач-офтальмолог – специалист с высшим медицинским образованием, который специализируется на диагностике, лечении и профилактике глазных заболеваний.', 
          src: 'Офтальмолог.jpg',
          route: 'Oftalmolog'
        },
        {
          name: 'Терапевт', 
          desc: 'Терапевты являются первым звеном, способным помочь решить любые проблемы со здоровьем: назначить диагностику и лечение, направить к узким специалистам, помочь с госпитализацией.', 
          src: 'терапевт.jpeg',
          route: 'Terapevt'
        },
        {
          name: 'Уролог', 
          desc: 'Это специалист, занимающийся диагностикой и лечением заболеваний органов мочевыделительной системы у женщин и мужчин, а также коррекцией нарушений мужской половой сферы.', 
          src: 'Уролог.jpg',
          route: 'Urolog'
        },
        {
          name: 'Хирург', 
          desc: 'В медицинской практике существует категория заболеваний, при которых наиболее эффективными являются хирургические методы лечения, данные вопросы находятся в компетенции врача — хирурга.', 
          src: 'Хирург.jpg',
          route: 'Hirurg'
        },
        {
          name: 'Эндокринолог', 
          desc: 'Нарушение работы щитовидной железы и нарушение обмена углеводов — самые известные проблемы, с которыми обращаются к врачу-эндокринологу.', 
          src: 'Эндокринолог.jpg',
          route: 'Endocrinolog'
        },
        {
          name: 'Стоматолог-гигиенист',
          desc: 'Его основной задачей является качественная чистка, а также совершение процедур, нацеленных на укрепление зубной эмали и предотвращение развития кариеса.',
          src: 'Стоматолог.jpg',
          route: 'Stomatolog'
        },
        {
          name: 'Онколог-маммолог',
          desc: 'Гормональный фон женщины отражается на состоянии здоровья молочных желез и может спровоцировать возникновение опасных заболеваний. Именно поэтому женская грудь требует особого внимания и профилактического наблюдения у грамотного врача онколога-маммолога.',
          src: 'Маммолог.jpg',
          route: 'Mammolog'
        },
        // {
        //   name: 'Клинический фитотерапевт', 
        //   desc: 'Профессиональная деятельность фитотерапевта тесно связана практически со всеми медицинскими специальностями.', 
        //   src: 'Фитотерапевт.jpg',
        //   route: 'Fitoterapevt'
        // },
        {
          name: 'Медицинские комиссии', 
          desc: 'Все виды медицинских комиссий, которые вы можете пройти в Клинике Здоровья', 
          src: 'Медком.jpeg',
          route: 'Medcom'
        },
        {
          name: 'Процедурный кабинет', 
          desc: 'Услуги, оказываемые в процедурном кабинете', 
          src: 'ПроцедурныйКабинет.jpeg',
          route: 'Procedures'
        },
        {
          name: 'Функциональная диагностика', 
          desc: 'Прейскурант на услуги кабинета функциональной диагностики', 
          src: 'Диагностика.jpeg',
          route: 'Diagnostika'
        },
        {
          name: 'Рентген', 
          desc: 'Прайс-лист Рентгенологического кабинета', 
          src: 'Рентген.jpeg',
          route: 'Rentgen'
        },
        {
          name: 'УЗИ', 
          desc: 'Подготовка к УЗИ и прайс на услуги УЗИ-кабинета', 
          src: 'УЗИ.jpeg',
          route: 'USI'
        },
        {
          name: 'Анализы', 
          desc: 'Прайс на анализы', 
          src: 'Анализы.jpeg',
          route: 'Analysis'
        },
      ]
    }
  }
}
</script>

<style>
.v-service-page {
  text-align: center;
}
</style>
