<template>
<div class="v-service">
    <div class="service-about">
        <div class="service-header"> Клинический фитотерапевт </div>
        <div class="service-text">Консультацию проводит врач терапевт, клинический фитотерапевт <b>Беляева Дарья Александровна.</b> Длительность приема от 30 минут до 1 часа. </div>
        <div class="service-text"><b>Как построен приём:</b></div>
        <div class="service-text">
            1.	Сбор жалоб, анамнеза.<br>
            2.	Осмотр, пальпация, перкуссия, аускультация, измерение температуры тела, ад, сатурации, пульса, чдд, при необходимости другие инструментальные методы обследования<br>
            3.	Заполнение анкеты совместно с врачом<br>
            4.	Определение тактики ведения пациента<br>
            5.	Рекомендации по питанию, образу жизни, лечение<br>
            6.	Вопросы врачу.<br>
        </div>
        <div class="service-text"><b>Программы оздоровления:</b></div>
        <div class="service-text">
            1.	Иммуннокоррекция с помощью фитотерапии и нутрицевтиков<br>
            2.	Противопаразитарные программы<br>
            3.	Аллергические заболевания, коррекционные программы<br>
            4.	Аутоиммунные заболевания, коррекционные программы<br>
            5.	Программы общего оздоровления и очищения организма<br>
            6.	Желчный пузырь и печень, программы для нормализации желчеоттока<br>
            7.	Женские заболевания, фитокоррекция <br>
            8.	Программы при нарушениях сна<br>
            9.	Коррекция при менопаузальных состояниях<br>
            10.	Коррекционные программы при частых циститах, пиеонефритах<br>
            11.	Коррекционные программы при бронхолегочной патологии.<br>
        </div>
        <div class="service-text"><b>Чтобы прием прошел максимально продуктивно, перед приемом сдайте:</b></div>
        <div class="service-text">
            1.	ОАК (с лейкоцитарной формулой); <br>
            2.	биохимический анализ крови (о.белок, алт, аст, билирубины (общий, прямой, непрямой), ферритин, сывороточное железо, креатинин, глюкоза, щелочная фосфотаза);<br>
            3.	ТТГ, св.Т3, св.Т4, АТ к ПТО;<br>
            4.	при женских заболеваниях: (пролактин, эстрадиол 5-7 день цикла, прогестерон 21-23 день цикла, лг, фсг, тестостерон 3-5 день цикла).<br>
        </div>
        <div class="service-header">Прейскурант на услуги врача клинического фитотерапевта</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in fito_services" :key="service">
                <div class="price-row">
                    <div class="price-name">{{service.name}}</div>
                    <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'v-fitoterapevt',
    props: {
    },
    data() {
        return {
            fito_services: [
                {
                    name: 'Первичный прием',
                    price: '2 500 руб'
                },
                {
                    name: 'Повторный прием',
                    price: '2 300 руб'
                },
            ],
        }
    }
}
</script>

<style lang="scss">
</style>