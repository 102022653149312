<template>
  <div class="v-service">
    <div class="service-about">
        <div class="service-header">ЛОР</div>
        <div class="service-text">ЛОР-врач (оториноларинголог) – это узкопрофильный специалист, занимающийся профилактикой, диагностикой и лечением патологии органов слуха, обоняния, глотки, гортани и трахеи («ухо/горло/нос»), а также прилегающих к ним областей.</div>
        <div class="service-text">В компетенцию отоларинголога входит диагностика и лечение отита, фарингита, тонзиллита, воспаления носовых синусов (синуситов), острого и хронического ринита. Также данный специалист занимается коррекцией нарушений слуха, нарушений носового дыхания, анатомических аномалий и травматических повреждений, удалением доброкачественных новообразований лор-органов.</div>
        <div class="service-text"><b>Консультации отоларинголога требуют следующие ситуации:</b></div>
        <div class="service-text" style="text-align: left;">
            •	Головные боли, боли в области лба, щек и переносицы;<br>
            •	Болезненность или дискомфорт в ушах;<br>
            •	Снижение слуха;<br>
            •	Выделения из слухового прохода;<br>
            •	Необходимость удаления серной пробки;<br>
            •	Зависимость от сосудосуживающих препаратов, длительный насморк или затрудненное носовое дыхание;<br>
            •	Наличие в ЛОР-органах инородных предметов;<br>
            •	Храп;<br>
            •	Носовые кровотечения;<br>
            •	Боль в горле;<br>
            •	Ухудшение обоняния.<br>
        </div>

        <div class="service-header">Услуги врача-оториноларинголога</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in lor_services" :key="service">
                <div class="price-row">
                <div class="price-name">{{service.name}}</div>
                <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>

        <div class="service-header">Процедуры и манипуляции</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in lor_procedures" :key="service">
                <div class="price-row">
                <div class="price-name">{{service.name}}</div>
                <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>

        <div class="service-header">Анализы</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in lor_analysis" :key="service">
                <div class="price-row lor-analysis">
                <div class="price-name">{{service.name}}</div>
                <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>

        <div class="service-header">Эндоскопический осмотр ЛОР органов</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in endoscop_osmotr" :key="service">
                <div class="price-row">
                <div class="price-name">{{service.name}}</div>
                <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'v-gastr',
    props: {
    },
    mounted() {
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = "Клиника здоровья ЛОР";
        document.body.appendChild(meta);
    },
    data() {
        return {
            lor_services: [
                {
                    name: 'Консультация и инструментальный осмотр',
                    price: '2 000 руб'
                },
                {
                    name: 'Повторный прием',
                    price: '1 800 руб'
                },
            ],
            lor_procedures: [
                {
                    name: 'Анемизация, туалет полости носа, смазывание',
                    price: '250 руб',
                },
                {
                    name: 'Продувание по Политцеру с анемизацией слизистой носа',
                    price: '300 руб',
                },
                {
                    name: 'Вливание в гортань лекарственных препаратов',
                    price: '500 руб',
                },
                {
                    name: 'Смазывание слизистой зева лекарственными препаратами',
                    price: '150 руб',
                },
                {
                    name: 'Удаление патологического содержимого слухового прохода',
                    price: '500 руб',
                },
                {
                    name: 'Введение турунды в  полость среднего и наружного уха',
                    price: '200 руб',
                },
                {
                    name: 'Массаж барабанной перепонки',
                    price: '200 руб',
                },
                {
                    name: 'Прижигание кровоточащего сосуда',
                    price: '500 руб',
                },
                {
                    name: 'Парацентез барабанной перепонки',
                    price: '1 000 руб',
                },
                {
                    name: 'Лечение на аппарате «Тонзилор» (1 сеанс) вместе с вливанием смазыванием',
                    price: '600 руб',
                },
                {
                    name: 'Блокада слизистой носа (1 сеанс)',
                    price: '500 руб',
                },
                {
                    name: 'Ультразвуковая ингаляция (1 сеанс)',
                    price: '200 руб',
                },
                {
                    name: 'Лечение синусита (гайморита, фронтита) методом «перемещения» (1 сеанс)',
                    price: '550 руб',
                },
                {
                    name: 'Лазеро-магнитотерапия заболевания носа, уха (1 сеанс)',
                    price: '150 руб',
                },
                {
                    name: 'Местная анестезия без учета  анестетика инфильтрационная ',
                    price: '150 руб',
                },
                {
                    name: 'Аппликационная анестезия 10% раствором  лидокаина, 2-3% р-ра Дикаина',
                    price: '100 руб',
                },
                {
                    name: 'Взятие материала на бактериологический посев',
                    price: '250 руб',
                },
                {
                    name: 'Лазеротерапия уха, горла, носа (1 сеанс)',
                    price: '250 руб',
                },
                {
                    name: 'Промывание полости среднего уха антисептиками, через иглу, канюлю (1 сеанс)',
                    price: '550 руб',
                },
                {
                    name: 'Удаление грануляций среднего уха (прижигание, выкусывание)',
                    price: '500 руб',
                },
                {
                    name: 'Вскрытие отогематомы',
                    price: '700 руб',
                },
                {
                    name: 'Вскрытие фурункула носа, уха, лица с дренированием',
                    price: '1 200 руб',
                },
                {
                    name: 'Смена дренажа',
                    price: '500 руб',
                },
                {
                    name: 'Тампонада носа (передняя)',
                    price: '1 000 руб',
                },
                {
                    name: 'Тампонада носа (задняя)',
                    price: '1 500 руб',
                },
                {
                    name: 'Катетеризация пазухи с промыванием (без учета медикаментов) через ороантральное соустье',
                    price: '700 руб',
                },
                {
                    name: 'Риноцитограмма забор анализа',
                    price: '160 руб',
                },
                {
                    name: 'Катеризация Евстахиевой трубы с продувание и введение лекарственного вещества',
                    price: '700 руб',
                },
                {
                    name: 'Смазывание слухового прохода р-ом(спирт, брил.зел)',
                    price: '100 руб',
                },
                {
                    name: 'Удаление инородного тел из  глотки',
                    price: '700 руб',
                },
                {
                    name: 'Удаление инородного теле из уха',
                    price: '600 руб',
                },
                {
                    name: 'Пункция гайморовой пазухи ',
                    price: '1 500 руб',
                },
                {
                    name: 'Пункция гайморовой пазухи с катетеризацией (без стоимости катетера)',
                    price: '2000 руб',
                },
                {
                    name: 'Промывание в/пазухи через катетер',
                    price: '500 руб',
                },
                {
                    name: 'Фонофорез уха/горла/носа',
                    price: '500 руб ',
                },
                {
                    name: 'Вскрытие кист миндалин',
                    price: '900 руб',
                },
                {
                    name: 'Забор анализа на цитологическое исследование',
                    price: '200 руб',
                },
                {
                    name: 'Вскрытие абсцесса перегородки носа с дренированием',
                    price: '1 000 руб',
                },
                {
                    name: 'Вскрытие паратонзилярного абцесса',
                    price: '1 500 руб',
                },
                {
                    name: 'Удаление паппиломы глотки',
                    price: '1 150 руб',
                },
                {
                    name: 'Удаление серных пробок методом промывания из 1-го уха',
                    price: '450 руб',
                },
                {
                    name: 'Промывание миндалин асептическими растворами',
                    price: '400 руб',
                },
                {
                    name: 'Повторное дренирование гнойника',
                    price: '500 руб',
                },
                {
                    name: 'Лечение хронического тонзиллита на аппарате «Тонзилор» в сочетании с лазеротерапией',
                    price: '850 руб',
                },
                {
                    name: 'Меатотимпанальная блокада',
                    price: '500 руб',
                },
            ],
            lor_analysis: [
                {
                    name: 'Риноцитограмма',
                    price: '300 руб',
                },
                {
                    name: 'Цитологическое исследование соскобов и отпечатков с поверхностей барабанной перепонки, слизистых (миндалин, полости носа, гортани)',
                    price: '500 руб',
                },
                {
                    name: 'Бак. посев на микрофлору и чувствит. к антибиотикам отделяемого верхних дых.путей (зев)',
                    price: '470 руб',
                },
                {
                    name: 'Бак.посев на микрофлору без определения чувствит. к антибиотикам определяемого верхних дах.путей (зев)',
                    price: '430 руб',
                },
                {
                    name: 'Бак. посев на микрофлору и чувствит. к бактериофагам отделяемого верхних дых.путей (зев)',
                    price: '470 руб',
                },
                {
                    name: 'Бак.посев на золотистый стафилококк и отделяемого верхних дых путей (зев)',
                    price: '300 руб',
                },
                {
                    name: 'Бак.посев на коклюш (зев)',
                    price: '470 руб',
                },
                {
                    name: 'Бак.посев на возбудителя дифтерии отделяемого верхних дых путей (зев)',
                    price: '400 руб',
                },
                {
                    name: 'Бак. посев на микрофлору и чувствит. к антибиотикам отделяемого верхних дых.путей (нос)',
                    price: '470 руб',
                },
                {
                    name: 'Бак. пасев на миклофлору  без определения чуствит. к антибиотикам отделяемого верхни дах.путей (нос)',
                    price: '430 руб',
                },
                {
                    name: 'Бак. посев на микрофлору и чувствит. к бактериофагам отделяемого верхних дых.путей (нос)',
                    price: '470 руб',
                },
                {
                    name: 'Бак.посев на золотистый стафилококк  отделяемого верхних дых путей (нос)',
                    price: '300 руб',
                },
                {
                    name: 'Бак.посев на возбудителя дифтерии отделяемого верхних дых путей (нос и зев) 2 пробирки',
                    price: '400 руб',
                },
                {
                    name: 'Бак. посев на микрофлору и чувствит. к антибиотикам отделяемого верхних дых.путей (ухо)',
                    price: '470 руб',
                },
                {
                    name: 'Бак.посев на микрофлору без определения чуствит. к антибиотикам отделяемого верхних дых.путей (ухо)',
                    price: '430 руб',
                },
                {
                    name: 'Бак. посев на микрофлору и чувствит. к антибиотикам отделяемого верхних дых.путей (носоглотка)',
                    price: '470 руб',
                },
                {
                    name: 'Бак.посев на золотистый стафилококк и определение чувствит. к антибиотикам отделяемого верхних дых путей (носоглотка)',
                    price: '300 руб',
                },
                {
                    name: 'Бак.посев на Candida spp. и чувствит. к антимикотическим препаратам',
                    price: '430 руб',
                },
            ],
            endoscop_osmotr: [
                {
                    name: 'Отоскопия (ухо)',
                    price: '500 руб'
                },
                {
                    name: 'Риноскопия (полость носа + носоглотка)',
                    price: '500 руб'
                },
                {
                    name: 'Фаринголарингоскопия (горло + гортань)',
                    price: '600 руб'
                },
            ]
        }
    }
}
</script>

<style>
.lor-analysis{
    height: 110px;
}
@media screen and (max-width: 700px) {
.lor-analysis{
    height: 180px;
}
}
@media screen and (min-width: 701px) and  (max-width: 1100px){
.lor-analysis{
    height: 175px;
}
}
</style>
