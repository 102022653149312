<template>
  <div class="v-specialists-page">
    <div v-for="doctor in doctors" :key="doctor" style="display: inline-block;">
      <Card
        :name="doctor.name"
        :spec="doctor.spec"
        :img="doctor.src"
      />
    </div>
  </div>
</template>

<script>
import Card from '@/components/v-specialist-card.vue'

export default {
  name: 'v-specialists-page',
  components: {
    Card,
  },
  props: {
  },
  mounted() {
    const meta = document.createElement("meta");
    meta.name = "description";
    meta.content = "Клиника здоровья врачи специалисты";
    document.body.appendChild(meta);
  },
  data() {
    return {
      doctors: [
        {name: 'Сотников Евгений Иванович', spec: 'Врач акушер-гинеколог, врач ультразвуковой диагностики', src: 'Сотников Евгений Иванович.jpeg'},
        {name: 'Черникова Вероника Викторовна', spec: 'Врач оториноларинголог', src: 'Черникова Вероника Викторовна.jpeg'},
        {name: 'Чащина Ольга Константиновна', spec: 'Врач акушер-гинеколог, врач гинеколог-эндокринолог, врач ультразвуковой диагностики', src: 'Чащина Ольга Константиновна.jpeg'},
        {name: 'Ушакова Маргарита Андреевна', spec: 'Врач акушер-гинеколог, врач ультразвуковой диагностики', src: 'Ушакова Маргарита Андреевна.jpeg'},
        {name: 'Марченко Ольга Алексеевна', spec: 'Врач ультразвуковой диагностики', src: 'Марченко Ольга Алексеевна.jpeg'},
        // {name: 'Гладышев Алексей Владимирович', spec: 'Врач анестезиолог-реаниматолог, исполнительный директор', src: 'Гладышев Алексей Владимирович.jpeg'},
        // {name: 'Беляева Дарья Александровна', spec: 'Врач терапевт, врач фитотерапевт', src: 'Беляева Дарья Александровна.jpeg'},
        // {name: 'Ущенко Марина Сергеевна', spec: 'Врач дерматовенеролог', src: 'doctor2.jpeg'},
        {name: 'Кужугет Сайзана Владимировна', spec: 'Врач ультразвуковой диагностики', src: 'Кужугет Сайзана Владимировна.jpeg'},
        // {name: 'Санников Михаил Сергеевич', spec: 'Врач невролог, врач ультразвуковой диагностики', src: 'Санников Михаил Сергеевич.jpeg'},
        {name: 'Манчыылай Чейнеш Шой-ооловна', spec: 'Врач эндокринолог', src: 'Манчыылай Чейнеш Шой-ооловна.jpeg'},
        {name: 'Моторина Татьяна Борисовна', spec: 'Врач офтальмолог', src: 'Моторина Татьяна Борисовна.jpeg'},
        {name: 'Борзых Наталья Александровна', spec: 'Врач профпатолог', src: 'Борзых Наталья Александровна.jpeg'},
        // {name: 'Калинин Александр Иванович', spec: 'Врач психиатр-нарколог', src: 'Калинин Александр Иванович.jpeg'},
        // {name: 'Медведева Ольга Александровна', spec: 'Врач психиатр-нарколог', src: 'Медведева Ольга Александровна.jpeg'},
        {name: 'Исмаилов Расулжон Рахимжанович', spec: 'Врач хирург, врач ультразвуковой диагностики', src: 'Исмаилов Расул Рахимович.jpeg'},
        // {name: 'Рыбакова Татьяна Александровна', spec: 'Врач УЗД', src: 'doctor2.jpeg'},
        {name: 'Шульга Марина Андреевна', spec: 'Врач ультразвуковой диагностики', src: 'Шульга Марина Андреевна.jpeg'},
        {name: 'Рыбкин Андрей Львович', spec: 'Врач уролог', src: 'Рыбкин Андрей Львович.jpeg'},
        {name: 'Горлатых Вера Андреевна', spec: 'Врач стоматолог-гигиенист', src: 'Горлатых Вера Андреевна.jpeg'},
        {name: 'Козлова Лариса Леонидовна', spec: 'Врач терапевт', src: 'Козлова Лариса Леонидовна.jpeg'},
        {name: 'Абдуллин Григорий Рафаилович', spec: 'Врач онколог-маммолог', src: 'эмблема.jpg'},
        {name: 'Пономаренко Александр Геннадьевич', spec: 'Врач ультразвуковой диагностики', src: 'эмблема.jpg'},
        {name: 'Торощина Татьяна Владимировна', spec: 'Врач кардиолог', src: 'эмблема.jpg'},
        {name: 'Лубянский Дмитрий Владимирович', spec: 'Врач невролог', src: 'эмблема.jpg'},
        {name: 'Муродов Рахимджон Хусейнович', spec: 'Врач онколог', src: 'эмблема.jpg'},
        {name: 'Мусина Гузель Рафаэльевна', spec: 'Врач кардиолог', src: 'эмблема.jpg'},
        {name: 'Гринец Татьяна Евгеньевна', spec: 'Врач ультразвуковой диагностики', src: 'эмблема.jpg'},
      ]
    }
  }
}
</script>

<style>
.v-specialists-page {
  text-align: center;
}

</style>
