<template>
  <div class="v-service">
    <div class="service-about">
      <div class="service-header">Гинеколог</div>
      <div class="service-text">Врач-акушер-гинеколог – специалист с высшим медицинским образованием, который занимается наблюдением состояния репродуктивной системы женщины, лечением возможных заболеваний и профилактикой осложнений. Половое здоровье женщины – это залог её физического, психического и социального благополучия, важнейший фактор, определяющий качество жизни и возможность зачать, выносить и родить здорового ребенка. Поэтому крайне важно 1-2 раза в год посещать гинеколога для прохождения планового профилактического осмотра, позволяющего своевременно выявлять возможные нарушения.</div>
      <div class="service-text"><b>С чем обратиться к врачу-гинекологу?</b></div>
      <div class="service-text" style="text-align: left;">
        • Любое нарушение менструального цикла: скудные, обильные, нерегулярные, частые, болезненные выделения;<br>
        • Боли в нижней части живота в различные периоды менструального цикла;<br>
        • Дискомфорт и/или болезненные ощущения во время секса;<br>
        • Беспричинный стремительный набор веса;<br>
        • Избыточная волосистость тела;<br>
        • Воспалительные заболевания половых органов;<br>
        •	Консультирование по вопросам контрацепции;<br>
        •	Планирование и ведение беременности;<br>
        •	Заболевания шейки матки, влагалища и вульвы;<br>
        •	Образования яичников, матки, шейки матки, влагалища и вульвы (миомы, кисты и т.п.);<br>
        •	Заболевания эндометрия (гиперплазии, полипы);<br>
        •	Лечение климактерического синдрома, недержания мочи;<br>
        •	Лечение диффузных заболеваний молочных желёз (мастопатия) и др.<br>
      </div>
        <div class="service-header">Услуги</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in ginecolog_services" :key="service">
                <div class="price-row">
                <div class="price-name">{{service.name}}</div>
                <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>
        <div class="service-header">Процедуры и манипуляции</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in procedures" :key="service">
                <div class="price-row">
                <div class="price-name">{{service.name}}</div>
                <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>
        <div class="service-header">Гинекологические операции</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in operations" :key="service">
                <div class="price-row">
                <div class="price-name">{{service.name}}</div>
                <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'v-gastr',
    props: {
    },
    mounted() {
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = "Клиника здоровья гинеколог";
        document.body.appendChild(meta);
    },
    data() {
        return {
            ginecolog_services: [
                {
                    name: 'Первичный прием гинеколога (осмотр+консультация)',
                    price: '1 700 руб'
                },
                {
                    name: 'Первичный прием гинеколога (осмотр+консультация), включая скрининговое УЗИ',
                    price: '2 200 руб'
                },
                {
                    name: 'УЗИ гинекологическое + консультация гинеколога (без инструментального осмотра)',
                    price: '2 100 руб'
                },
                {
                    name: 'УЗИ гинекологическое + консультация гинеколога (без инструментального осмотра)',
                    price: '2 100 руб'
                },
                {
                    name: 'Консультация по беременности с осмотром (без УЗИ)',
                    price: '2 000 руб'
                },
                {
                    name: 'Консультация по беременности с осмотром для фарм. аборта',
                    price: '1 600 руб'
                },
                {
                    name: 'Повторный прием гинеколога с осмотром (в течении месяца) ',
                    price: '1 500 руб'
                },
                {
                    name: 'Повторный прием гинеколога без осмотра (в течении месяца), консультация по анализам',
                    price: '800 руб'
                },
                {
                    name: 'Консультация по контрацепции',
                    price: '500 руб'
                },
                {
                    name: 'Дополнительный осмотр после медикаментозного аборта (выполненный в клинике)',
                    price: '600 руб'
                },
                {
                    name: 'Первичный прием гинеколога-эндокринолога (осмотр+консультация)',
                    price: '1 900 руб'
                },
                {
                    name: 'Повторный прием гинеколога-эндокринолога (в течении месяца)',
                    price: '1 700 руб'
                },
            ],
            procedures: [
                {
                    name:'Биопсия ножевая, конхотомная ',
                    price:'1 300 руб',
                },
                {
                    name:'Биопсия радиоволновая',
                    price:'1 500 руб',
                },
                {
                    name:'Введение ВМС (без стоимости спирали)',
                    price:'1 000 руб',
                },
                {
                    name:'Введение ВМС (Мирена, Goldlyli) без стоимости спирали',
                    price:'1 000 руб',
                },
                {
                    name:'Введение ВМК («ПЕССАРИЯ»)',
                    price:'1 000 руб',
                },
                {
                    name:'Удаление ВМС (пессария) (за «усики»)',
                    price:'750 руб',
                },
                {
                    name:'Удаление «ВМС» крючком',
                    price:'1 200 руб',
                },
                {
                    name:'Взятие мазка на флору (без стоимости анализа) ',
                    price:'100 руб',
                },
                {
                    name:'Взятие мазка на жидкостную цитологию (без стоимости анализа)',
                    price:'300 руб',
                },
                {
                    name:'Взятие мазка на онкоцитологию (без стоимости анализа)',
                    price:'200 руб',
                },
                {
                    name:'Забор бак.материала для бакпосева и ПЦР',
                    price:'250 руб',
                },
                {
                    name:'Подбор пессария',
                    price:'600 руб',
                },
                {
                    name:'Местное лечение кольпита',
                    price:'350 руб',
                },
                {
                    name:'Массаж при заболеваниях женских половых органов (1 сеанс)',
                    price:'700 руб',
                },
                {
                    name:'Введение парацервикально (анестезия)',
                    price:'250 руб',
                },
                {
                    name:'Аппликационная анестезия',
                    price:'100 руб',
                },
                {
                    name:'Местная анестезия с учетом анестетика',
                    price:'170 руб',
                },
                {
                    name:'Внутривенная анестезия',
                    price:'170 руб',
                },
                {
                    name:'Удаление полипа шейки матки',
                    price:'500 руб',
                },
                {
                    name:'Бужирование цервикального канала',
                    price:'700 руб',
                },
                {
                    name:'Вскрытие абсцесса бартолиневой железы',
                    price:'1 200 руб',
                },
                {
                    name:'Диатермоэлектроэксцессия (ДЭЭ) (прижигание, аппарат ФОТЕК)',
                    price:'1 000 руб',
                },
                {
                    name:'Лечение шейки аппаратом «Сургидрон»',
                    price:'2 800 руб',
                },
                {
                    name:'Кольпоскопия',
                    price:'1 300 руб',
                },
                {
                    name:'Медикаментозный аборт до 6 нед (без стоимости приема)',
                    price:'17 000 руб',
                },
                {
                    name:'Снятие послеоперационных швов, обработка, перевязка',
                    price:'650 руб',
                },
                {
                    name:'Пайпель биопсия эндометрия',
                    price:'1 400 руб',
                },
                {
                    name:'Обработка шейки матки р-ром солковагина (без стоимости препарата)',
                    price:'450 руб',
                },
                {
                    name:'Установка подкожного противозачаточного имплантата (без учета стоимости препарата)',
                    price:'1 600 руб',
                },
                {
                    name:'Удаление папиллом, кондилом, гинекологических вирусов радиоволновым методом',
                    price:'1 500 - 2 500 руб',
                },
                {
                    name:'Спираль в/м Юнона «Био-Т» с серебром',
                    price:'1 000 руб',
                },
            ],
            operations: [
                {
                    name: 'Медицинский аборт с анестезиологическим пособием (срок беременности до 8 нед) ',
                    price: '15 000 руб',
                },
                {
                    name: 'Медицинский аборт с анестезиологическим пособием (срок беременности 8-10 нед)',
                    price: '17 000 руб',
                },
                {
                    name: 'Медицинский аборт с анестезиологическим пособием (срок беременности 10-12 нед)',
                    price: '20 000 руб',
                },
                {
                    name: 'Лечебно-диагностическое выскабливание (включая стоимость гистологического исследования)',
                    price: '13 000 руб',
                },
                {
                    name: 'Видеогистероскопия (включая стоимость гистологического исследования)',
                    price: '13 500 руб',
                },
                {
                    name: 'Лечение шейки под наркозом',
                    price: '12 000 руб',
                },
                {
                    name: 'Восстановление девственной плевы',
                    price: '10 000 руб',
                },
                {
                    name: 'Удаление ВМС под наркозом',
                    price: '8 500 руб',
                },
            ],
        }
    }
}
</script>

<style>
</style>
