<template>
  <div class="v-service">
    <div class="service-about">
      <div class="service-header">Медицинские комиссии</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in medcom_services" :key="service">
                <div class="price-row lor-analysis">
                <div class="price-name">{{service.name}}</div>
                <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'v-gastr',
    props: {
    },
    mounted() {
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = "Клиника здоровья медкомиссии водительская комиссия";
        document.body.appendChild(meta);
    },
    data() {
        return {
            medcom_services: [
                {
                    name: 'Медицинское освидетельствование о допуске к управлению тракторами и самоходными машинами (форма 071/у)',
                    price: '1 200 руб'
                },
                {
                    name: 'Медицинское освидетельствование о допуске к управлению транспортным cредством  категория В + трактором и самоходными машинами  ',
                    price: '1 400 руб'
                },
                {
                    name: 'Медицинское освидетельствование о допуске к управлению транспортным cредством категории B,C,D,E+трактором и самоходными машинами',
                    price: '1 950 руб'
                },
                {
                    name: 'Медицинское освидетельствование о допуске к управлению транспортным cредством  категория В',
                    price: '900 руб'
                },
                {
                    name: 'Медицинское освидетельствование о допуске к управлению транспортным cредством категории B,C,D,E',
                    price: '1 750 руб'
                },
                {
                    name: 'Первичный медицинский осмотр по санитарной книжке (мужчины)',
                    price: '2 850 руб'
                },
                {
                    name: 'Первичный медицинский осмотр по санитарной книжке (женщины)',
                    price: '3 750 руб'
                },
                {
                    name: 'Периодический медицинский осмотр по санитарной книжке (мужчины)',
                    price: '2 300 руб'
                },
                {
                    name: 'Периодический медицинский осмотр по санитарной книжке (женщины)',
                    price: '3 200 руб'
                },
                {
                    name: 'Медицинский осмотр (повторное заключение при условии подтверждения  мед.документацией)',
                    price: 'от 800 руб'
                },
                {
                    name: 'Медицинский осмотр по направлению (цена зависит от вредных факторов, указанных в направлении)',
                    price: 'от 2 000 до 7 000 руб'
                },
                {
                    name: 'Дубликат санитарной книжки',
                    price: '800 руб'
                },
                {
                    name: 'Медицинский осмотр по форме 001 ГС/У',
                    price: '1 000 руб'
                },
                {
                    name: 'Медицинский осмотр по форме 086/у (без стоимости анализов)',
                    price: '1 500 руб'
                },
                {
                    name: 'Медицинский осмотр по форме 086/у (мужчины)',
                    price: '1 700 руб'
                },
                {
                    name: 'Медицинский осмотр по форме 086/у (женщины)',
                    price: '2 000 руб'
                },
            ],
        }
    }
}
</script>

<style>
</style>
